import * as React from "react"
import Layout from "../components/layout"

import moment from "moment"
import { v4 as uuidv4 } from 'uuid';

import { Helmet } from "react-helmet";

import {
    Container,
    Heading,
    Text,
    SlideFade,
    SimpleGrid,
    LinkBox,
    FormControl,
    FormLabel,
    Switch,
    Image,
    LinkOverlay
} from '@chakra-ui/react'

import { graphql, useStaticQuery } from "gatsby"


// markup
const IndexPage = () => {
    const [isPersonal, setPersonal] = React.useState(false)

    const toggleWork = () => {
        isPersonal ? setPersonal(false) : setPersonal(true)
    }

    const data = useStaticQuery(
        graphql`
            query {
                allMicrocmsWorks(sort: {fields: date, order: DESC}) {
                    nodes {
                        title
                        url
                        date
                        image {
                            url
                        }
                        category
                    }
                }
            }  
        `
    )

    let WorksGridData = data.allMicrocmsWorks.nodes.map((node: any): React.ReactNode => {
        if (!isPersonal) {
            if (node.category.includes("依頼")) {
                return (
                    <SlideFade in={true} key={uuidv4()}>
                        <LinkBox _hover={{
                            transform: "translateY(-10px)"
                        }}
                            transition="ease"
                            transitionDuration="300ms"
                        >
                            <Image src={node.image.url} alt="" />
                            <LinkOverlay href={node.url} isExternal>
                                <Heading noOfLines={1} as="h2" size="md" marginTop="5">{node.title}</Heading>
                            </LinkOverlay>
                            <Text marginTop="2">{moment(node.date).format("YYYY/MM/DD")}</Text>
                        </LinkBox>
                    </SlideFade>
                )
            }
        } else {
            if (node.category.includes("依頼") || node.category.includes("自主制作")) {
                return (
                    <SlideFade in={true} key={uuidv4()}>
                        <LinkBox _hover={{
                            transform: "translateY(-10px)"
                        }}
                            transition="ease"
                            transitionDuration="300ms"
                        >
                            <Image src={node.image.url} alt="" />
                            <LinkOverlay href={node.url} isExternal>
                                <Heading noOfLines={1} as="h2" size="md" marginTop="5">{node.title}</Heading>
                            </LinkOverlay>
                            <Text marginTop="2">{moment(node.date).format("YYYY/MM/DD")}</Text>
                        </LinkBox>
                    </SlideFade>
                )
            }
        }
    })

    return (
        <Layout>
            <Helmet>
                <title>Top | boo1213.work</title>
                <meta property="og:title" content="Top | boo1213.work" />
            </Helmet>
            <FormControl display='flex' alignItems='center' marginBottom="3">
                <FormLabel htmlFor='email-alerts' mb='0'>
                    自主制作を表示
                </FormLabel>
                <Switch onChange={toggleWork} id='email-alerts' />
            </FormControl>
            <SimpleGrid marginTop="5" columns={{ sm: 2, md: 3 }} spacing={5}>
                {WorksGridData}
            </SimpleGrid>
        </Layout>
    )
}

export default IndexPage